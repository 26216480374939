.question2Container {
  height: 100%;
  width: 100%;
  margin-top: 1rem;
}
.tableHeader {
  font-size: 1.375rem;
  font-weight: 700;
  margin-bottom: 2rem;
  &:first-child {
    justify-content: start;
    padding-left: 2rem;
  }
}

.tableHeader::before {
  display: none;
}

.rankHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > div:first-child {
    margin-bottom: 0.75rem;
  }
}

.rankTotal {
  font-weight: 700;
}

.tableRow {
  text-align: center;
  padding: 3.5px 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.tableInfoTypes {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.125rem;

  & > div {
    display: flex;
    align-items: center;
    margin-left: 1.31rem;
    cursor: pointer;
  }
}

.radiolable {
  font-size: 1.375rem;
  margin-right: 0.562rem;
}

.radiolable {
  text-transform: capitalize;
}

.both {
  display: flex;
  flex-direction: column;
  // align-items: center;
}

.maxValue {
  background-color: #f5ae6b;
  font-weight: 700;
}
