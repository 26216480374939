.lineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineExpand {
  -webkit-line-clamp: initial;
}

.expandButton {
  color: var(--color-fontGreen);
  font-weight: 600;
  cursor: pointer;
  font-size: 1rem;
}