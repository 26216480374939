.progressBar {
  width: 100%;
  height: 100%;
  max-height: 2.5rem;
  min-height: 2rem;
  background: #DADADA;
  overflow: hidden;
  border: 1px solid var(--color-menuGreen);
}

.activeProgress {
  height: 100%;
  max-height: 2.5rem;
  min-height: 2rem;
  background-color: var(--color-menuGreen);
}

.activeProgressContent{
  width: 100%;
  height: 100%;
  margin-left: 2.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}