.modal {
  width: 51.5rem;
  height: 43.75rem;

  & > div {
    padding: 1.875rem;
  }
}

.innerModal {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 1.375rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-left: 1.375rem;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 1rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.75rem;

  & > button {
    width: 10.125rem;
  }

  & > button:last-child {
    margin-left: 1.125rem;
  }
}