.background {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.288);
  }
  
  .modal {
    // height: 20rem;
    width: 45rem;
    z-index: 13;
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    background-color: var(--color-appDefault);
    box-shadow: 0px 4px 25px #4F4F4F;
    color: white;
  }