.root {
  width: 30%;
  height: 100%;
  overflow-y: auto;
  margin-right: 1rem;
  padding-right: 1rem;
}

.formItem {
  width: 100%;
}

.formItemMain {
  width: 100%;
  background-color: var(--color-gray5);
  display: flex;
  padding: 1rem;

  & > div {
    width: calc(50% - 1rem);
  }

  & > .formItemLabel {
    width: 50%;
  }
}

.formInputWrapper {
  width: 100%;
  display: flex;
  align-items: center;

  & > input {
    width: 100%;
    padding: 1rem 1rem;
    font-size: 1.125rem;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 0.15rem;
  }
}
.accessCopyIcon {
  width: 1.75rem;
  height: 2rem;
  margin-left: 0.75rem;
  padding-top: 0.25rem;
  margin-right: 0.75rem;
  cursor: pointer;
}

.formInputLabel {
  color: white;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
}

.formItemLabel {
  font-size: 1.375rem;
  line-height: 1.5rem;
  padding-top: 0.25rem;
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.formActions {
  display: flex;
  justify-content: space-between;
  margin: 0.6rem 0;
  padding: 0 1rem;

  & > button {
    font-size: 1rem;
    height: 3rem;
    width: 8rem;
  }
}

.formRightAlign {
  justify-content: flex-end;
}

.formItemCreate {
  font-size: 1.25rem;
  line-height: 1.5rem;
  .formItemMain {
    padding-left: 2rem;
  }
  .formItemTitle {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.375rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

  .formActions {
    background: var(--color-linearGreen);
    width: 100%;
    padding: 1rem;
    margin: 0;
    border-radius: 0px 0px 1rem 1rem;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding-left: 2rem;
    .formButton {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      Button {
        width: 8rem;
      }
    }
    .formButtonGroup {
      width: 50%;
      display: flex;
      justify-content: space-between;
      Button {
        width: 8rem;
      }
    }
  }

  .formItemHolder {
    margin-right: 1rem;
  }

  .addButton {
    background-color: #61667e;
    border-radius: 0.5rem;
  }

  .formItemMain {
    background-color: var(--color-menuGreen);
  }
}

.clearButton {
  color: var(--color-appDefault);
  background-color: transparent;
}

.formItemUpdate {
  .formItemMain {
    background-color: transparent;
  }
}
