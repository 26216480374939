.root {
  display: flex;
  align-items: center;
}

.eventTimeHolder {
  display: flex;
}

.plusMinus {
  width: 1.75rem;
  min-width: 1.75rem;
  user-select: none;
  color: white;

  & > div {
    height: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4f4f4f;
    cursor: pointer;
    font-size: 1.375rem;
  }

  & > div:first-child {
    margin-bottom: 0.2rem;
  }
}

.eventDaysLabel, .eventTimeLabel, .eventHoursLabel {
  font-size: 1.35rem;
  margin: 0 0.75rem;
}

.eventTimeLabel {
  margin-right: 0;
}

.eventTimeInput {
  input {
    width: 100%;
    max-width: 3.5rem;
    height: 3rem;
    background-color: var(--color-whitegray);
    overflow: hidden;
    padding: 0 0.5rem;
    position: relative;
    border: 0;
    font-size: 1.35rem;
    text-align: center;
  }
}
