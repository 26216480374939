.mcChart1Root {
  width: 100%;
  display: flex;
  height: calc(100% - 2rem);
  margin-top: 2rem;
  position: relative;
}

.mcChart1Content {
  font-size: 1.325rem;
  line-height: 1.5rem;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.mcChartItem:not(:last-child) {
  margin-bottom: 1.06rem;
}

.mcChartItem {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
}

.mcChartAnalysEmpty {
  display: flex;
  justify-content: center;
}

.mcChartTitleBox {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  .mcChartHeaderTitle {
    width: 15rem;
    margin-right: 1rem;
  }
  .mcChartHeaderValue {
    width: 15rem;
    font-weight: 400;
  }
  &:first-child {
    font-weight: 700;
    font-size: 1.35rem;
    .mcChartHeaderValue {
      font-weight: 700;
    }
  }
}

.mcChartTitle {
  margin-bottom: 0.75rem;
  width: 20%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  line-height: 1.75rem;
  display: flex;
  flex-direction: column;
  .mcChartTitleHeader {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .mcChartTitleBody {
    font-weight: 400;
    font-size: 1.2rem;
  }
  .mcChartTitleFooter {
    font-weight: 400;
    font-size: 1.2rem;
  }
}
.showLabelsText {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
  position: absolute;
  top: -15px;
  left: -15px;
  cursor: pointer;
}

.mcChartInfo {
  display: flex;
  min-width: 70%;

  & > div:first-child {
    flex-grow: 1;
    margin-right: 2.31rem;
    height: 2.5rem;
  }

  & > div:last-child {
    // width: 10%;
    // font-weight: 700;
    // margin-top: 0.5rem;
  }

  .progressItemLabel {
    color: var(--color-fontGreen);
    line-height: 2.5rem;
  }
  .progressItemPercent {
    min-width: 8rem;
  }
  .mcChart {
    width: 5rem;
    margin-left: 1rem;
    .imageView {
      width: 100%;
      cursor: pointer;
      color: var(--color-fontGreen);
      font-weight: 500;
    }
  }
}

.commentsBoard {
  margin-top: 5rem;
}

.commentHeader {
  font-weight: bold;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-lightGray);
  color: var(--color-appDefault);
  height: 3rem;
  align-items: center;
  padding: 0 1rem;
}

.commentItem {
  margin-top: 0.8rem;
  word-break: break-all;
}

.commentsBody {
  padding: 0 1rem;
}
