.root {
  width: 3.06rem;
  height: 3.06rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.icon {
  background-image: url('../../assets/nImages/sort_asc.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 3rem;
  height: 3.02rem;
  margin-right: 1rem;
  &.desc{
    background-image: url('../../assets/nImages/sort_desc.svg');
  }
}

// .arrow {
//   background-image: url('../../assets/nImages/black_arrow-down.svg');
//   background-position: center;
//   background-size: contain;
//   background-repeat: no-repeat;
//   width: 1.5rem;
//   height: 4rem;
//   margin-right: 3px;
// }

.disabled {
  opacity: 0.5;
  cursor: default;
}
