.dropdownRoot {
  position: relative;
}

.dropdown {
  width: 100%;
  display: flex;
  border-radius: 0.375rem;
  height: 3rem;
  overflow: hidden;
  background-color: var(--color-whitegray);
  cursor: pointer;

  & > div:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    margin-left: 1.18rem;
  }

  .arrowWrapper {
    height: 100%;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow {
    width: 1rem;
    height: 0.57rem;
    background-image: url('../../../assets/images/arrowDown.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(270deg);
  }
}

.dropdown:hover {
  .arrowWrapper {
    background-color: var(--color-gray10);
  }
  .arrow {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(70deg) brightness(107%) contrast(101%);
  }
}

.dropdownActive {
  .arrowWrapper {
    background-color: var(--color-gray10);
  }
  .arrow {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(70deg) brightness(107%) contrast(101%);
  }
}

.dropdownModal {
  position: absolute;
  left: calc(100% + 3px);
  top: 0;
  width: max-content;
  // padding: 0.375rem;
  border: 2px solid var(--color-gray5);
  background-color: white;
  z-index: 19;
}