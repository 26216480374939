.root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginContainer {
  width: 70%;
  height: 80%;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
}
.formBoard {
  border: 3px solid var(--color-menuGreen);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .loginImage {
    display: flex;
    height: 10rem;
    width: 15rem;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 80%;
    }
  }
  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .username {
      width: 19rem;
    }

    .password {
      margin-top: 2.9rem;
      width: 19rem;
    }
  }
}

.inputLabel {
  margin-bottom: 0.44rem;
  margin-left: 0.44rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.buttonWrapper {
  margin-top: 4.125rem;
  width: 12.125rem;

  button {
    width: 100%;
  }
}
