.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.contentBoard {
  display: flex;
  height: 100%;
  width: 100%;
}

.caseEventBody {
  display: flex;
  height: 100%;
  width: 100%;
}
.reportBody {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .currentQuestionHeader {
    width: 100%;
    padding: 1.5rem;
    margin: 1rem;
    margin-top: 0;
    background-color: #4f4f4f;
    color: white;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.patientVisitDetails{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
  .patientVisitBtn {
    margin-left: 1rem;
    width: auto;
    min-width: 7.5rem;
    padding: 5px;
    height: 3rem;
    border: 1px solid var(--color-menuGreen);
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: transparent;
    color: var(--color-menuGreen);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.pvActiveVisit {
      background-color: var(--color-menuGreen);
      color: white;
      cursor: default;
    }
  }
}

.currentPage {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  display: flex;

  &.moderatorView{
    height: calc(100% - 5rem);
    overflow-y: auto;
  }
}

.noEvent {
  margin-top: 2rem;
  width: 100%;
}

.reportFilterCard {
  margin-bottom: 1rem;
  padding-bottom: 1.5rem;
}
