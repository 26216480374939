.section {
  width: 100%;
  height: 100%;
  padding-bottom: 1.32rem;
  display: flex;
}

.therapeutic {
  margin-right: 1.43rem;
  width: 25%;
}

.case {
  margin-right: 1.43rem;
  width: calc(50% - 2.86rem);
}

.language {
  width: 25%
}
