.pastAnalysis {
  margin-top: 2.78rem;
  flex-grow: 1;
  overflow-y: auto;
}

.cardItem {
  margin-bottom: 0.68rem;
}

.content {
  padding: 0 2.93rem;

}

.casesBody {
  display: flex;
  & > div:nth-child(1) {
    margin-right: 0.3rem;
  }
}

.content > div {
  margin-bottom: .7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content > div:last-child {
  margin-bottom: 0;
}

.bold {
  font-weight: 700;
}