.commentsBoard {
  margin-top: 3rem;
}

.commentHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
}

.imageView {
  cursor: pointer;
}

.multipleChoiceContainer{
  width: 100%;
  height: 100%;
}

.lastCell { 
  padding-left: 2rem;
}