.menu {
  height: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.menuContainer {
  height: calc(100% - 10rem);
  background-color: var(--color-white);
  padding: 2rem 0rem 0rem 2.31rem;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 25px rgba(170, 154, 130, 0.5);
  z-index: 0;
  position: relative;
  justify-content: space-between;
}

.menuHolder {
  height: calc(100% - 5rem);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10rem;
  justify-content: center;

  .headerLogo__icon {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .headerLogo__svg {
    width: 17.125rem;
    height: 6rem;
  }
}

.menuNavbar {
  // flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - 90px);
}

.customMenuContainer {
  margin-left: 1.5rem;
}

.signOut {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    background: #61667e;
    color: white;
    font-size: 1.25rem;
    font-weight: 700;
    border-radius: 3px;
  }
}

.signOutIcon {
  background-image: url('../../assets/images/logout.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}

.menuItem {
  color: var(--color-appDefault);
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0.8rem;
}

.menuBox {
  .menuImage {
    width: 1.68rem;
    // height: 1.68rem;
    margin-right: 0.5rem;
  }
  display: flex;
  align-items: center;
}

.menuTitle {
  display: flex;
}
.activeItem {
  color: var(--color-menuGreen);
  background: linear-gradient(
    270deg,
    rgba(192, 221, 198, 0.35) -7.24%,
    rgba(79, 177, 194, 0.35) 126.92%
  );
  width: 100%;
  display: inherit;
}

.multiItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
.menuSupraItem {
  padding-left: 2.75rem;
  .dropdownMenuItem {
    padding-left: 2rem;
  }
}

.dropdownTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;
}

.arrow {
  background-image: url('../../assets/nImages/arrow_up.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 13px;
  transform: rotateX(180deg);
}

.arrowActive {
  transform: rotateY(180deg);
}

.dropdownMenuItem {
  padding: 0.6rem 0.6rem 0.6rem 3rem;
  font-weight: 600;
}

.imageInteractive {
  font-weight: 700;
  margin-left: -0.85rem;
}
.dropdownMenuItem:first-child {
  margin-top: 0;
}

.menuGreenBubble {
  background: url('../../assets/nImages/green_bubble.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 70px;
  height: 84px;
  position: absolute;
  bottom: 0;
  right: 5px;
}
