.root {
  display: flex;
  flex-direction: column;
  height: 100%;

}

.accessRootContainer {
  position: relative;
}

.container {
  width: 100%;
  height: 100%;
}

.content {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  padding-bottom: 2rem;
  width: 100%;
  height: calc(100% - 2rem);

  .accessList,
  .accessEvents {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
}

.actionItems {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  div{
    position: absolute;
    height: 5rem;
    width: 5rem;
    cursor: pointer;
    bottom: 3px;
    &:hover {
      transform: scale(1.1, 1.1);
    }
  }

  .createUserBtn {
    right: 3px;
    background: url('../../assets/nImages/add.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.line {
  height: 100%;
  width: 1px;
  background-color: black;
}