.table {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.table__header {
  margin-bottom: 0.5rem;
  width: 100%;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table__headerCell {
  padding-left: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;
  font-size: 1.37rem;
  font-weight: 700;
  min-height: 3.5rem;
  margin-right: 0.5rem;
  color: var(--color-appDefault);
  background-color: var(--color-lightGray);

  & *:first-letter {
    text-transform: uppercase;
  }

  &:first-child:before {
    display: none;
  }
}

.table__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100% - 5rem);
  overflow-y: auto;
}

.table__row {
  margin-bottom: 0.5rem;
  padding: 0.25rem 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  &:hover {
    background: var(--color-whitegray);
    padding-right: 1rem;
  }
}

.table__cell {
  padding-left: 1.25rem;
  font-size: 1.325rem;
  font-weight: 600;
  line-height: 1.69rem;
  margin-right: 0.5rem;
  color: var(--color-appDefault);
   &:first-child {
    color: var(--color-menuGreen);
  }
}
