.casesRoot {
  height: 100%;
  display: flex;
  flex-direction: column;  
}

.headingActions {
  display: flex;
  align-items: center;
}

.headingItem {
  margin-left: 1.25rem;
}

.cards {
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 3.93rem;
  padding-right: 1.43rem;
}

.addButton {
  border-radius: 0.375rem;
  height: 3.06rem;
  font-size: 1.125rem;
  width: 13rem;
  font-weight: 500;
}

.headingActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headingActionInfo {
  .headingActionInfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    label{
      font-size: 1.25rem;
      color: var(--color-appDefault);
      font-weight: 700;
    }
    span{
      font-size: 1.25rem;
      margin-left: 5px;
    }
  }
}
.headingActionItem {
  position: absolute;
  top: 5rem;
  right: 3rem;
  display: flex;
  flex-direction: row;
  .sortDropDownButton{
    background: url('../../assets/nImages/black_arrow-down.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 25px;
    margin-top: 5px;
    background-position: right;
    gap: 0.5rem;
 }
}

.addPatientCaseHolder {
  display: flex;
  align-items: center;
  flex-direction: column; 
  cursor: pointer;
  .addButton {
    background-image: url('../../assets/nImages/add_event.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 3rem;
    height: 2rem;
  }
  .addPatientCaseText {
    font-size: 1rem;
    line-height: 2rem;
  }
  &:hover {
    transform: scale(1.1, 1.1);
  }
}