html {
  box-sizing: border-box;
  // font-size: 9px;
  font-family: 'Poppins', sans-serif;
  color: var(--color-appDefault);

  ::-webkit-scrollbar {
    width: 0.69rem;
    height: 0.45rem;
    background-color: var(--color-gray8);
    border-radius: 0.34rem;
    overflow: hidden;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-menuGreen);
    border-radius: 0.34rem;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 0.69rem;
  background-color: var(--color-gray8);
  border-radius: 0.34rem;
  overflow: hidden;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-gray9);
  border-radius: 0.34rem;
}

@media (min-width: 1920px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1800px) and (max-width: 1920px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1600px) and (max-width: 1800px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1440px) and (max-width: 1600px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1366px) and (max-width: 1440px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1150px) and (max-width: 1280px) {
  html {
    font-size: 9px;
  }
}

@media (min-width: 1024px) and (max-width: 1150px) {
  html {
    font-size: 9px;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 8px;
  }
}

.react-datepicker__triangle {
  display: none !important;
}

.paragraph {
  margin: 0.3rem 0;
  word-break: break-word;
}

// React Responsice Carousel Custom Sytles
.carousel-root {
  width: inherit;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000;
}

.carousel.carousel-slider {
  overflow: visible;
}

.carousel .control-dots {
  position: absolute;
  bottom: -30px;
}

.carousel .control-dots .dot {
  background: #ffffff;
  border: 2px solid var(--color-darkblue);
  width: 10px;
  height: 10px;
  box-shadow: none;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: var(--color-darkblue);
}

.carousel .slide img {
  width: fit-content;
}

@media screen and (min-width: 1301px) {
  .carousel .slide img {
    max-width: 600px;
  }
}

@media screen and (max-width: 1300px) {
  .carousel .slide img {
    max-width: 600px;
  }
}

@media screen and (max-width: 1200px) {
  .carousel .slide img {
    max-width: 500px;
  }
}

@media screen and (max-width: 1000px) {
  .carousel .slide img {
    max-width: 400px;
  }
}

@media screen and (max-width: 700px) {
  .carousel .slide img {
    max-width: 300px;
  }
}

@media screen and (max-width: 300px) {
  .carousel .slide img {
    max-width: 200px;
  }
}

.card_container_outer {
  background-color: var(--color-white);
  padding: 1rem;
  overflow: hidden;
  height: calc(100% - 9rem);
  width: 100%;
  border-radius: 0 0 10px 0;
  box-shadow: 0 4px 25px rgb(170 154 130 / 80%);
  z-index: 0;
  display: flex;
}
.card_holder {
  border: 3px solid #4fb1c2;
  width: 100%;
  height: 100%;  
  padding: 1.5rem 2rem;
}

.card_container_inner {
  background: url('../nImages/paper_texture.svg');
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  padding: 10px;
}

.cards {
  flex-grow: 1;
  padding: 0.5rem 1.5rem;
  height: 100%;
  overflow-y: auto;
}
