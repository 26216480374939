.settings_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 500;
}

.settings {
  padding-left: 2.06rem;
}

.settingItem {
  display: flex;
  align-items: center;
  width: 100%;
}

.settingItem:not(:last-child) {
  margin-bottom: 3rem;
}

.settingLable {
  width: 11rem;
  margin-right: 2.875rem;
  font-size: 1.3rem;
  line-height: 1.75rem;
}

.settingInput {
  flex-grow: 1;
  margin-right: 2.75rem;
  font-size: 1.3rem;
  line-height: 1.75rem;
}

.linkButton {
  // background-color: var(--color-gray5);
  height: 3rem;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.375rem;
  width: 8rem;
  letter-spacing: 0.7px;
}

.tabs {
  margin-top: 5rem;
  display: flex;

  & > div:not(:first-child) {
    margin-left: 2rem;
  }
}

.imagesButton {
  // background-color: var(--color-gray5);
  height: 3rem;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.375rem;
  width: 9rem;
  letter-spacing: 0.7px;
}

.languagesButton {
  height: 3rem;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.375rem;
  width: 14rem;
  letter-spacing: 0.7px;
}

.timerWrapper {
  display: flex;
  align-items: center;
}

.timerInput {
  width: 6.5rem;
  height: 3rem;
  border-radius: 0.375rem;
  display: flex;
  background-color: var(--color-whitegray);
  margin-right: 2.75rem;

  & > div:first-child {
    flex-grow: 1;
  }
}

.plusMinus {
  width: 1.75rem;
  min-width: 1.75rem;
  user-select: none;
  color: white;

  & > div {
    height: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4f4f4f;
    cursor: pointer;
    font-size: 1.375rem;
  }

  & > div:first-child {
    margin-bottom: 0.2rem;
  }
}

.infiniteCheckbox {
  display: flex;
  align-items: center;
  font-size: 1.375rem;
  margin: 0 2rem;
  cursor: pointer;

  & > div:first-child {
    margin-right: 0.5rem;
  }
}
