.modal {
  width: 51.5rem;
  height: 43.75rem;

  & > div {
    padding: 1.875rem;
  }
}

.innerModal {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 1.375rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-left: 1.375rem;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 1rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.75rem;

  & > button {
    width: 10.125rem;
  }

  & > button:last-child {
    margin-left: 1.125rem;
  }
}

.nextSelection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: var(--color-whitegray);
  padding: 0.5rem;
  border-radius: 0.375rem;
}

.nextSelectText {
  font-size: 1.125rem;
}

.buttonContext {
  display: flex;
  align-items: center;
}

.arrowBottom {
  margin-left: 0.4rem;
  width: 1.2rem;
  height: 1.2rem;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.nextSelectButton {
  margin-left: 0.9rem;

  & > button {
    height: 2.5rem;
    font-size: 1rem;
    border-radius: 0.2rem;

  }
}