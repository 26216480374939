// @import "src/assets/styles/media";

.carouselPopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.carouselPopup__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90vw;
  height: 90vh;
}

.carouselPopup__pic {
  width: 100%;
  height: 100%;
  padding: 0.81rem;
  background-color: var(--color-white);
  border: 8px solid var(--color-darkblue);
  border-radius: 0.75rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselPopup__img {
  width: fit-content;
  object-position: center;
  object-fit: contain;
}

.carouselPopupClose {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

}

.carouselPopup__close {
  position: absolute;
  top: -1.845rem;
  right: -1.845rem;
  padding: 0.81rem;
  width: 3.69rem;
  height: 3.69rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-darkblue);
  cursor: pointer;
  z-index: 15;
}

.carouselPopup__closeSvg {
  fill: var(--color-white);
  width: 100%;
  height: 100%;
  object-position: center;
}

.carouselPopup__sliderButton {
  background: none;
  border: none;
  width: 50px;
  padding: 0px;
  cursor: pointer;
}

.carouselPopup__sliderButton__nextPos {
  position: absolute;
  top: 50%;
  z-index: 2;
  right: 0;
}

.carouselPopup__sliderButton__prevPos {
  position: absolute;
  top:50%;
  z-index: 2;
  left: 0;
}

.carouselPopup__sliderButton__img {
  width: 100%;
  height: 100%;
}

.carouselPopup__pictureContainer {
  margin: auto;
}

@media screen and (min-width: 100px) and (max-width: 599px) {
  .carouselPopup__sliderButton__nextPos {
    display: none;
  }

  .carouselPopup__sliderButton__prevPos {
    display: none;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .carouselPopup__sliderButton__nextPos {
    right: 5%;
  }

  .carouselPopup__sliderButton__prevPos {
    left: 5%;
  }
}

@media screen and (min-width: 701px) and (max-width: 1300px) {
  .carouselPopup__sliderButton__nextPos {
    right: 6%;
  }

  .carouselPopup__sliderButton__prevPos {
    left: 6%;
  }
}

