.card {
  width: 100%;
  background-color: var(--color-whitegray);
  display: flex;
  border-radius: 0.375rem;
  &.eventAccessCard{
    border-radius: 0;
    margin: 0;
  }
  &.filter {
    background-color: transparent;
    .arrowField {
      width: 2rem;
      min-width: 1rem;
      .arrow {
        margin-top: 0;
        background-image: url('../../../assets/nImages/arrow_up.svg');
        transform: rotateX(-180deg);
      }
      .arrowActive {
        transform: rotateX(0deg);
      }
    }
    &.closable {
      padding: 0;
    }
    .content {
      flex-grow: 1;
      display: flex;
      align-items: flex-start;
      height: 2rem;
      flex-direction: column;
      width: 70%;
      white-space: nowrap;
      font-weight: 500;
      max-width: 100%;
      &.contentActive {
        height: auto;
        overflow: auto;
        margin-bottom: 1rem;
      }
    }
    &.check {
      .content {
        min-height: 3rem;
      }
    }
  }
  padding: 0.9rem 1rem;
  box-sizing: border-box;
  &.cardShort {
    background-color: transparent;
    border-radius: 0.2rem;
    border: 1px solid var(--color-menuGreen);
    &.checked {
      background-color: var(--color-menuGreen);
    }
    .actions{
      min-width: 0;
    }
  }
}

.closable {
  padding: 0.9rem 0;
}

.nonCloseable {
  .content {
    padding-left: 2rem;
  }
}

.cardActive {
  height: unset;
}

.arrowField {
  width: 4.75rem;
  min-width: 4.75rem;
  display: flex;
  justify-content: center;
  // height: 100%;
  cursor: pointer;
}

.arrowFieldShort {
  width: 3rem;
  min-width: 3rem;
  .arrow {
    background-image: url('../../../assets/nImages/arrow_up.svg');
    transform: rotateX(-180deg);
  }
  .arrowActive {
    transform: rotateX(0deg);
  }
}

.checked {
  .arrowFieldShort {
    .arrow {
      filter: invert(7%) sepia(46%) saturate(100%) hue-rotate(232deg) brightness(140%)
        contrast(267%);
    }
  }
}

.arrow {
  margin-top: 0.55rem;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('../../../assets/nImages/black_arrow-down.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.arrowActive {
  transform: rotateX(180deg);
}

.content {
  flex-grow: 1;
  overflow: hidden;
  word-break: break-word;
  max-width: calc(100% - 42rem);
}

.size_s {
  font-size: 1.125rem;
  & .arrowField {
    width: 3rem;
    min-width: 3rem;
  }
  .content{
    max-width: 100%;
    word-break: normal;
  }
}

.size_m {
  font-size: 1.375rem;
  .content {
    max-width: calc(100% - 5rem);
    word-break: normal;
  }
}

.actions {
  display: flex;
  align-items: center;
  // min-width:100;
  justify-content: flex-end;
}

.filterIcon {
  background-image: url('../../../assets/nImages/filter_icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 2.2rem;
  width: 3rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.checked {
  background-color: var(--color-menuGreen);

  & .arrow {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(70deg) brightness(107%) contrast(101%);
  }

  & * {
    color: white;
  }
}

.check > * {
  cursor: pointer;
}

.headingCheckboxRoot {
  display: flex;
  margin-right: 1.5rem;
  font-size: 1.125rem;
  cursor: pointer;
}

.headingCheckbox {
  margin-right: 1rem;
  margin-top: 0.75rem;

  & > div {
    background-color: white;
  }
}

.headingCheckboxTitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: white;
}