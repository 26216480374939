.groupChart1Root {
  width: 100%;
  display: flex;
  height: calc(100% - 2rem);
  margin-top: 2rem;
  position: relative;
}

.groupChart1Content {
  font-size: 1.325rem;
  line-height: 1.5rem;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: column;
  // margin-top: 2rem;
}

.groupChartItem:not(:last-child) {
  margin-bottom: 1.06rem;
}

.groupChartItem {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
}

.groupChartTitleBox {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  .groupChartHeaderTitle {
    width: 15rem;
    margin-right: 1rem;
    font-weight: 600;
    color: var(--color-fontGreen);
  }
  .groupChartHeaderValue {
    width: 15rem;
    font-weight: 400;
  }
  &:first-child {
    font-weight: 700;
    font-size: 1.35rem;
    .groupChartHeaderValue {
      font-weight: 700;
    }
  }
}

.groupChartTitle {
  margin-bottom: 0.75rem;
  width: 20%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  line-height: 1.75rem;
  display: flex;
  flex-direction: column;

  &.showingLabels{
    width: 30%;
  }
  .groupChartTitleHeader {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .groupChartTitleBody {
    font-weight: 400;
    font-size: 1.2rem;
  }
  .groupChartTitleFooter {
    font-weight: 400;
    font-size: 1.2rem;
  }
}
.labelBox{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: auto;
  height: 2rem;
}

.showLabelsText {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
  // position: absolute;
  // top: -15px;
  // left: -5px;
  cursor: pointer;
  margin-bottom: 1.5rem;
  width: 100%;
  label {
    margin-top: 0.35rem;
    cursor: pointer;
  }
  span {
    width: 1rem;
    background-repeat: no-repeat;
    margin: 0 0.5rem;
    background-position: center;
  }
}

.showLabelArrow {
  background-image: url('../../../../../../assets/nImages/VectorrightArrow_Black.svg');
}

.hideLabelArrow {
  background-image: url('../../../../../../assets/nImages/VectorleftArrow_Black.svg');
}

.groupChartInfo {
  display: flex;
  min-width: 70%;

  &.showingLabels{
    min-width: 50%;
  }

  & > div:first-child {
    flex-grow: 1;
    margin-right: 2.31rem;
    height: 2.5rem;
  }

  & > div:last-child {
    // width: 10%;
    // font-weight: 700;
    // margin-top: 0.5rem;
  }

  .progressItemLabel {
    color: var(--color-fontGreen);
    line-height: 2.5rem;
  }
  .progressItemPercent {
    min-width: 8rem;
  }
  .groupChartImage {
    width: 5rem;
    margin-left: 1rem;
    .imageView {
      width: 100%;
      cursor: pointer;
      color: var(--color-fontGreen);
      font-weight: 500;
    }
  }
}

.commentsBoard {
  margin-top: 5rem;
}

.commentHeader {
  font-weight: bold;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}

.commentItem {
  margin-top: 0.8rem;
  word-break: break-all;
}
