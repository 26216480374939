.expectedRoot {
  width: 6.5rem;
  height: 3rem;
  border-radius: 0.375rem;
  display: flex;
  background-color: var(--color-whitegray);

  & > div:first-child {
    flex-grow: 1;
  }
}

.plusMinus {
  width: 1.75rem;
  min-width: 1.75rem;
  background-color: white;
  border: 2px solid #898989;
  border-radius: 0 0.375rem 0.375rem 0;
  user-select: none;

  & > div {
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  & > div:first-child {
    border-bottom: 2px solid #898989;
  }
}