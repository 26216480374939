.futureRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.addEventHolder {
  display: flex;
  align-items: flex-end;
  flex-direction: column; 
  cursor: pointer;
  .addButton {
    background-image: url('../../assets/nImages/add_event.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 3rem;
    height: 2rem;
  }
  .addEventText {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.headingActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headingActionInfo {
  .headingActionInfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    label{
      font-size: 1.25rem;
      color: var(--color-white);
      font-weight: 700;
    }
    span{
      font-size: 1.25rem;
      margin-left: 5px;
    }
  }
}
.headingActionItem {
  position: absolute;
  top: 5rem;
  right: 3rem;
  display: flex;
  flex-direction: row;
  .sortDropDownButton{
     background: url('../../assets/nImages/black_arrow-down.svg'); 
     background-size: contain;
     background-repeat: no-repeat;
     width: 150px;
     height: 25px;
     margin-top: 5px;
     background-position: right;
     gap: 0.5rem;
  }
}
