.satisfactionItem {
  width: 100%;
  min-width: max-content;
  background-color: #d9d9d9;
  padding: 1rem 4rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.375rem;
  font-weight: 700;
  text-align: center;
  height: 4rem;
  display: flex;
  align-items: center;
}

.starsBoard {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border: 2px solid var(--color-menuGreen);
  border-radius: 5px;
  padding: 2px;
  width: 90%;
}

.progressRow {
  display: flex;
  align-items: center;
  font-size: 1.375rem;
  font-weight: 500;

  & > div:first-child {
    width: 4.1em;
    text-align: end;
  }

  & > div:nth-child(2) {
    flex-grow: 1;
    margin: 0 0.875rem;
    height: 2.375rem;
    width: 20rem;
  }

  & > div:last-child {
    width: 3rem;
  }
}

.progressRow:not(:first-child) {
  margin-top: 0.625rem;
}

.starsBoard {
  display: flex;
  align-items: center;
}

.starInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2.19rem;

  & > div:first-child {
    margin-bottom: 0.375rem;
    font-size: 1.25rem;
    font-weight: 700;
  }
  & > div:last-child {
    margin-bottom: 0.375rem;
    font-size: 1.125rem;
    font-weight: 500;
  }
}

.bold {
  font-weight: 700;
}
