.actions {
    display: flex;
    justify-content: space-between;
  }
  
  .row {
    display: flex;
    align-items: center;
  }
  
  .row > div:first-child {
    width: 20rem;
    min-width: 20rem;
    font-size: 1.375rem;
  }
  
  .row > div:last-child {
    flex-grow: 1;
  }
  
  
  .radioLable {
    margin-left: 1rem;
    font-size: 1.125rem;
  }
  
  .radioBoard {
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
  
  .radioItem {
    display: flex;
    align-items: center;
  }
  
  .radioItem:not(:first-child) {
    margin-left: 5rem;
  }
  
  .cancelButton {
    margin-right: 1.18rem;
  }
  
  .shortInput {
    width: 100%;
  }
  
  .grayButton {
    background-color: var(--color-gray5);
    border-radius: 0.375rem;
    font-size: 1.125rem;
    height: 2.5rem;
    font-weight: 700;
  }
  
  .chooseButton {
    width: 6.5rem;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
  }
  
  .dropzone {
    display: flex;
    align-items: center;
  }
  
  .fileInput {
    width: 0;
    height: 0;
    z-index: -1;
    visibility: hidden;
  }
  
  .patientRowWrapper {
    display: flex;
    align-items: center;
  }
  
  .patientRowWrapper:not(:last-child) {
    margin-bottom: 1rem;
  }
  
  .patientRow {
    background-color: var(--color-whitegray);
  }
  
  .timeZoneList {
    padding: 0.3rem 0;
    margin: 0.375rem;
    width: 39.5rem;
    display: flex;
    flex-wrap: wrap;
  }
  
  .timeZoneItem {
    // width: 1.75rem;
    // height: 1.75rem;
    width: fit-content;
    margin: 0.5rem 0.4rem;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    cursor: pointer;
  }
  
  .timeZoneItem:hover {
    background-color: var(--color-whitegray);
  }
  
  .timeZoneDropModal {
    top: 2.94rem;
  }
  
  .visitDropModal {
    top: -0.4rem;
  }
  
  .visitList {
    display: flex;
    flex-direction: column;
    width: 8.875rem;
    padding: 0.4rem 0;
    font-size: 1rem;
  }
  
  .visitItem {
    padding: 0.4rem 0.6rem; 
    cursor: pointer;
  }
  
  .visitItem:hover {
    background-color: var(--color-whitegray);
    // color: white;
  }
  
  .activeItem {
    background-color: var(--color-gray5);
    color: white;
    cursor: default;
  }
  
  .activeVisitItem {
    background-color: var(--color-whitegray);
    cursor: default;
  }
  
  .textarea {
    width: 100% !important;
    height: 4.7rem !important;
    resize: none;
    background-color: var(--color-whitegray);
    border-radius: 0.375rem;
    border: none;
    outline: none;
    padding: 1rem 1.125rem;
    font-size: 1.125rem;
  }
  
  .required {
    color: red;
  }
  
  .addRowButton {
    width: 14.69rem;
  }
  
  .historyItem {
    display: flex;
    align-items: center;
  }
  
  .historyItem:not(:first-child) {
    margin-top: .7rem;
  }
  
  .section {
    width: 100%;
    border-bottom: 2px solid var(--color-gray4);;
    padding-bottom: 2.62rem;
    padding-top: 2.62rem;
    padding-left: 1rem;
  }
  
  .lastSection {
    border: none;
  }
  
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-left: 0;
  }
  
  .navItem {
    font-size: 1.125rem;
    margin: 0 3rem;
    cursor: pointer;
    padding: 0.8rem 1.8rem;
    border-radius: 0.375rem;
  }
  
  .navItem_active {
    color: white;
    background-color: var(--color-darkblue);
    cursor: default;
  }
  
  .sectionActions {
    display: flex;
    justify-content: flex-end;
    margin-top: -0.8rem;
    margin-bottom: 1.64rem;
  
    & > button {
      height: 2.525rem;
      border-radius: 0.375rem;
      font-size: 1rem;
      font-weight: bold;
      width: 7rem;
    }
  }
  
  .clearButton {
    margin-right: 1.5rem;
    background-color: var(--color-gray5);
  }
  
  .avatarSection {
    padding-left: 1rem;
    display: flex;
  }
  
  .imageActionField {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2rem;
    margin-top: 2rem;
  
    & > div {
      margin: 0.6rem 0;
    }
  }
  
  .maxPixels {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .patientCases__content {
    // padding: 1.625rem 3.31rem 1.625rem 1.44rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //height: 13.31rem;
    // width: 55.81rem;
    border: 0.81rem solid var(--color-lightgreen1--02);
    border-radius: 2.5rem;
  }
  
  .patientCases__information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .patientCases__details {
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
  // .patientCases__picture {
  //   height: 8.88rem;
  //   width: 6.34rem;
  //   min-width: 6.34rem;
  //   border-radius: 1.25rem;
  //   overflow: hidden;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-shrink: 0;
  // }
  
  .patientCases__img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
  
  .patientCases__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0.97rem;
    flex: 1;
    margin-left: 3rem;
  }
  
  .patientCases__name {
    font-size: 1.37rem;
    font-weight: 700;
    color: var(--color-black);
  }
  
  .patientCases__gender {
    font-size: 1.37rem;
    font-weight: 400;
    color: var(--color-black);
    margin-top: 1rem;
  }
  
  .patientCases__description {
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--color-black);
    margin-top: 1rem;
  }
  
  .patientCases__start {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
  
  }
  
  .patientCases__history {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5rem;
  }
  
  .patientCases__historyTitle {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .patientCases__historyText {
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: 400;
  }
  
  .mobileStartButton {
    margin-top: 2rem;
  }
  
  .patientCases__historyText_paragraph {
    margin: 0.625rem 0;
  }
  
  .disabled {
    opacity: 0.4;
    cursor: default;
  }
  
  .imageCard {
    flex-grow: 1;
    height: 10rem;
    background-color: var(--color-whitegray);
    border-radius: 0.375rem;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
  }
  
  .imageCardName {
    flex: 1;
    overflow-wrap: anywhere;
  }
  
  .imageCardImage {
    height: 100%;
    width: 8rem;
  
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  
  .imageCardView {
    width: 6rem;
    margin-left: 1rem;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
  
  .hidden {
    visibility: hidden;
  }
  
  .viewRow {
    font-size: 1.2rem;
  }
  
  .share {
    font-size: 1.125rem;
    font-weight: bold;
    cursor: pointer;
  }
  
  .patientButton {
    width: 15rem;
  }

  .infoBlock {
    text-align: center;
    padding: 0.5rem;
  }

.bold {
  font-weight: 600;
}
