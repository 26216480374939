.switch {
  position: relative;
  display: inline-block;
  width: 3.2rem;
  height: 1.8rem;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.6rem;
  width: 1.6rem;
  left: 0.1rem;
  bottom: 0.1rem;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.active {
  .slider {
    background-color: var(--color-darkblue);
  }
  .slider:before {
    -webkit-transform: translateX(1.4rem);
    -ms-transform: translateX(1.4rem);
    transform: translateX(1.4rem);
  }
}

// .active .slider:before {
//   -webkit-transform: translateX(1.4rem);
//   -ms-transform: translateX(1.4rem);
//   transform: translateX(1.4rem);
// }

/* Rounded sliders */
.slider.round {
  border-radius: 1.8rem;
}

.slider.round:before {
  border-radius: 50%;
}