.root {
  flex: 1;
  height: 100%;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 4px 25px rgba(170, 154, 130, 0.8);
  border-radius: 0px 0px 10px 0px;
  padding-bottom: 0.5rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.8rem;
  background-color: var(--color-appDefault);
  width: 100%;
  border-radius: 0.2rem;
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.3rem 0.8rem;
  margin-bottom: 1.5rem;
  color: white;
}

.list {
  flex: 1;
  overflow: auto;
  height: 100%;
  margin: 0 0.75rem;
  padding: 0 0.75rem;
}

.listTitle {
  background-color: var(--color-appDefault);
  color: white;
  padding: 0.5rem 0.8rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;

  &.secondTitle {
    margin-top: 2rem;
  }
}

.listItem {
  // margin-bottom: 0.5rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  margin-right: 1.75rem;
  margin-top: 1rem;

  &>button {
    font-size: 1rem;
    height: 3rem;
    width: 8rem;
    background-color: #61667E;
  }
}

.filterIcon {
  background-image: url('../../../../assets/nImages/filter_white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 2.2rem;
  width: 2.6rem;
  cursor: pointer;
}