.cardItem {
  margin-bottom: 1rem;
  &.eventAccess{
    margin-bottom: 0;
    border-bottom: 0.5px solid #969393;
  }
}

.casesBody {
  display: flex;
  & > div:nth-child(1) {
    margin-right: 0.3rem;
  }
}
.content > div {
  gap: 5px;
}
.content {
  line-height: 2rem;
  font-weight: 600;
  .content_eventDetails {
    .approvedEvent {
      color: var(--color-appDefault);
    }
  }
}

.content > div:last-child {
  margin-bottom: 0.1rem;
}

.bold {
  font-weight: 700;
}

.actions {
  margin-right: 2.25rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.edit {
  background-image: url('../../../../assets/images/edit.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.56rem;
  height: 1.56rem;
  cursor: pointer;
}

.dotsWrapper {
  position: relative;
}

.dots {
  background-image: url('../../../../assets/images/dots.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 0.5rem;
  height: 1.87rem;
  margin-left: 1.48rem;
  cursor: pointer;
}

.shareDropdown {
  left: unset !important;
  top: 3px !important;
  left: -0.8rem !important;
}

.dotsDropdown {
  left: unset !important;
  top: 3px !important;
  right: 0 !important;
}

.dotsDropdownList {
  padding: 1rem 0;
  font-size: 1.125rem;
  cursor: default;
}

.dotsDropdownItem {
  padding: 1rem 1.375rem;
  cursor: pointer;
  color: black !important;
}

.dotsDropdownItem:hover {
  background-color: var(--color-whitegray);
  // color: white;
}

.actionItem {
  margin-left: 1.48rem;
  font-size: 1.375rem;
  font-weight: 700;
  cursor: pointer;
  max-width: 9.2rem;
  word-break: break-word;
  white-space: pre-wrap;
}

.deleteAction {
  color: var(--color-red);
  font-size: 1.125rem;
  font-weight: 700;
  cursor: pointer;
}

.warning {
  color: var(--color-red);
}

.approvedEvent {
  color: var(--color-fontGreen);
  font-weight: 700;
}

.draftEvent {
  font-weight: 700;
}
