.starsEmpty {
  width: 13.75rem;
  height: calc(13.75rem / 5);
  overflow: hidden;
  background-size: 20% 100%;
  background-image: url('../../assets/images/starEmpty.svg');
}

.starsFull {
  height: 100%;
  background-repeat: repeat-x;
  background-size: calc(13.75rem/5) 100%;
  background-image: url('../../assets/nImages/star_full.svg');
}
