.actions {
  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 1.64rem;
}

.row > div:first-child {
  width: 12rem;
  min-width: 12rem;
  max-width: 12rem;
  margin-right: 3.125rem;
  font-size: 1.375rem;
}

.row > div:last-child {
  flex-grow: 1;
}

.dateBoard {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
}

.dateBoard > div:nth-child(2) {
  margin-left: 2.18rem;
  margin-right: 0.5rem;
}

.datepicker {
  width: 40%;
}

.radioLable {
  margin-left: 1rem;
  font-size: 1.125rem;
}

.radioBoard {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.radioItem {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 4rem;
  }
}

.cancelButton {
  margin-right: 1.125rem;
}

.patientRowWrapper {
  display: flex;
  align-items: center;
}

.patientRowWrapper:not(:last-child) {
  margin-bottom: 1.625rem;
}

.patientRow {
  background-color: var(--color-whitegray);
}

.patientButton {
  height: 2.5rem;
  border-radius: 0.375rem;
  background-color: var(--color-gray5);
  font-size: 1.125rem;
  font-weight: 700;
}

.recycleIcon {
  width: 1.5rem;
  height: 1.7rem;
  background-image: url('../../../../assets/images/recycle.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 0.875rem;
  cursor: pointer;
}

.shortInput {
  width: 13.6rem;
} 

.areaDropModal {
  top: -0.4rem;
  z-index: 15;
}

.areaList {
  display: flex;
  flex-direction: column;
  width: 8.875rem;
  padding: 0.4rem 0;
  font-size: 1rem;
}

.areaItem {
  padding: 0.4rem 0.6rem; 
  cursor: pointer;
}

.areaItem:hover {
  background-color: var(--color-whitegray);
  // color: white;
}

.activeItem {
  background-color: var(--color-whitegray);
  // color: white;
  cursor: default;
}

.required {
  color: red;
}

.view * {
  cursor: default !important;
}

.event_img_preview {
  display: block;
  width: auto;
  height: auto;
  border: 2px dotted darkgray;
  margin-left: 10px;
  text-align: center;
}

.event_logo_preview, .event_bg_preview {
  display: block;
  width: auto;
  height: auto;
  border: 2px dotted darkgray;
  margin-left: 10px;
  text-align: center;
  max-height: 10rem;
}

.event_bg_preview {
  max-height: 15rem;
}

.fileInput {
  width: 0;
  height: 0;
  z-index: -1;
  visibility: hidden;
}

.chooseButton {
  width: 10.12rem;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.grayButton {
  background-color: var(--color-gray5);
  border-radius: 0.375rem;
  font-size: 1.125rem;
  height: 2.5rem;
  font-weight: 500;
}

.customEventImage {
  // width: max-content;
  padding: 0.5rem;
}

.eventImageContainer, .eventBackgroundContainer {
  margin-bottom: 1rem;

  img {
    max-height: 15rem;
  }
}

.inputTextInfo {
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: 1rem;
}

.timeLabel {
  font-size: 1.375rem;
}

.customDisclaimerTextBoxHolder {
  display: flex;
  align-items: center;
  .customDisclaimerTextBox {
    width: 100%;
    height: 100%;
    background-color: var(--color-whitegray);
    outline: none;
    border: none;
    font-size: 1.3rem;
    padding: 1.25rem;
  }
}

.timeTextLabel {
  display: flex;
  flex-direction: column;
  font-size: 1.375rem;
  line-height: 1.5rem;
  margin-left: 2rem;
}