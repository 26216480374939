.row {
  width: 100%;
  min-height: 3rem;
  border-radius: 0.375rem;
  font-size: 1.125rem;
  padding: 0.7rem 2rem;
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
  }
}