.actions {
  margin-top: 1.56rem;
  display: flex;
  justify-content: space-between;

  button {
    width: 12.12rem;
  }
}

.filterButton {
  margin-left: 2rem;
}