.pieChartRoot {
  width: 100%;
  display: flex;
  height: 100%;
  //   overflow-y: auto;
  // margin-top: 1rem;
}

.pieChartContent {
  font-size: 1.325rem;
  line-height: 1.5rem;
  font-weight: 600;
  width: 100%;
  height: 100%;
}

.pieChartItem {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  flex-wrap: wrap;
}

.pieChartInfo {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  align-items: center;
  margin: 1rem;
  .pieChartLabel {
    font-weight: 700;
    margin-bottom: 0.5rem;
    display: block;
  }
}

.pieChartLegend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .pieChartLegendHolder{
    padding: 0.5rem;
  }
  .pieChartLegendColor {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px;
  }
}
