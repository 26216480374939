.modal {
  width: 105rem;
  height: 50rem;

  & > div {
    padding: 1.875rem;
  }
}

.innerModal {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 1.375rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-left: 1.375rem;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.75rem;

  & > button {
    width: 10.125rem;
  }
}

.createBoard {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  & > div:not(:first-child) {
    margin-left: 1rem;
  }
}

.addButton {
  height: 3rem;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 0.375rem;
  width: 11rem;
}

.dictionaryItem {
  display: flex;
  align-items: center;
  width: 100%;
}

.dictionaryItem:not(:last-child) {
  margin-bottom: 1.625rem;
}

.dictionaryLable {
  width: 7rem;
  margin-right: 2.875rem;
  font-size: 1.125rem;
  font-weight: bold;
}

.dictionaryInput {
  flex-grow: 1;
  margin-right: 2.75rem;
}

.linkButton {
  // background-color: var(--color-gray5);
  height: 3rem;
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 0.375rem;
  width: 8rem;
  letter-spacing: .7px;
}

.recycleIcon {
  width: 1.5rem;
  height: 1.7rem;
  background-image: url('../../../../assets/images/recycle.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 0.875rem;
  cursor: pointer;
}

.deleteButton {
  margin-left: 0.6rem;
}