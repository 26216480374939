.viewRow {
  width: 100%;
  border-radius: 0.375rem;
  font-size: 1.125rem;
  padding: 0.625rem 0;
  padding-right: 3px;
  overflow: hidden;
  
  & > div {
    overflow-y: auto;
    min-height: calc(3rem - 1.25rem);
    padding: 3px 1.125rem;
    padding-left: 0;
    max-height: 5.3rem;
  }
}