.root {
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: white;
}

.list {
  flex: 1;
  padding: 0 1rem;
  height: 100%;
  overflow-y: auto;
}

.listInfoHolder{
  display: flex;
  width: 100%;
  background-color: #dadada;
}

.updateUserHolder{
  display: flex;
  width: 100%;
  background-color: #90CBD5;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #90CBD5;
}

.listItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
}

.listItemActive > .listItemInfo {
  color: white;
  cursor: default;
}

.listItemInfo {
  display: flex;
  flex: 1;
  border-radius: 0.2rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;

  cursor: pointer;
  & > div {
    width: 50%;
    padding: 0.7rem 0.8rem;
  }
}

.recycleWrapper {
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recycleIcon {
  width: 1.25rem;
  height: 1.35rem;
  background-image: url('../../../../assets/nImages/trash.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.noModerator {
  text-align: center;
  font-weight: 700;
  padding: 0.7rem 0.8rem;
  margin: 0.4rem 3rem 0.4rem 0;
  border-radius: 0.2rem;
  background-color: var(--color-whitegray);
}