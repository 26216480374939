.textareaWrapper {
  width: 100%;
  padding: 0.5rem 0;
  background-color: var(--color-whitegray);
  border-radius: 0.375rem;
  overflow: hidden;
}

.textarea {
  width: 100% !important;
  height: 4rem !important;
  resize: none;
  background-color: var(--color-whitegray);
  border: none;
  outline: none;
  padding: 0 1.125rem;
  font-size: 1.125rem;
}