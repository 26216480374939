.container {
//   display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  .header {
    display: flex;
    flex-direction: row;
    height: 3.5rem;
    width: 100%;
    background: #969393;
    color: white;
    padding: 1rem;
    font-size: 1.312rem;
    font-weight: 700;
    line-height: 1.5rem;
    .headLabel {
      padding-left: 10%;
      font-weight: 600;   
    }
    .headCount{
      font-size: 1.5rem;
    }
  }
  .body {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 19%;
    padding-right: 10%;
    .catHeader {
      color: var(--color-menuGreen);
      font-size: 1.3rem;
      line-height: 2rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    .questionAnswer {
      font-size: 1.2rem;
      line-height: 1.75rem;
      list-style: disc;
      .question {
        font-weight: 700;
      }
      .answer{
        padding-left: 0.5rem;
      }
    }
  }
}
