.root {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 0 1.78rem;


  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    font-weight: 700;
  }

  & > div:first-child {
    height: 4rem;
    font-size: 1.375rem;
    line-height: 1.5rem;
  }

  .info {
    background-color: #dadada;
    border: 1px solid #DADADA;
    border-radius: 5px;
    height: 7.5rem;
    font-size: 2rem;
  }
}



