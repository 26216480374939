.noItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  font-size: 1.125rem;
  font-weight: 700;
  cursor: default;
}

.short {
  height: 1.55rem;
}

.analys {
  height: 6rem;
  font-size: 1.375rem;
}