.physicalChart1Root {
  width: 100%;
  display: flex;
  height: calc(100% - 2rem);
  margin-top: 2rem;
}

.physicalChart1Content {
  font-size: 1.325rem;
  line-height: 1.5rem;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.physicalChartItem:not(:last-child) {
  margin-bottom: 1.06rem;
}

.physicalChartItem {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
}

.physicalChartTitle {
  margin-bottom: 0.75rem;
  width: 20%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  line-height: 1.75rem;
  display: flex;
  flex-direction: column;
  .physicalChartTitleHeader {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .physicalChartTitleBody {
    font-weight: 400;
    font-size: 1.2rem;
  }
  .physicalChartTitleFooter {
    font-weight: 400;
    font-size: 1.2rem;
  }
}

.physicalChartInfo {
  display: flex;
  width: 75%;

  & > div:first-child {
    flex-grow: 1;
    margin-right: 2.31rem;
    height: 2.5rem;
  }

  & > div:last-child {
    // width: 10%;
    // font-weight: 700;
    // margin-top: 0.5rem;
  }

  .progressItemLabel {
    color: var(--color-fontGreen);
    line-height: 2.5rem;
  }
  .progressItemPercent {
    min-width: 8rem;
  }
  .physicalChartImage {
    width: 5rem;
    margin-left: 1rem;
    .imageView{
      width: 100%;
      cursor: pointer;
      color: var(--color-fontGreen);
      font-weight: 500;
    }
  }
}
