.tableViewContainer{
  min-height: 45vh;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.pointer {
  cursor: pointer;
}

.headerCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.lastCell{
  padding-left: 2.5rem;
}
.dropdownIconWrapper {
  width: 28px;
  height: 20px;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: white;
  // border: 1px solid var(--color-gray2);
  cursor: pointer;
}

.dropdownIcon {
  width: 100%;
  height: 80%;
  background-image: url('../../../../../assets/nImages/filter_icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.dropdownIconActive {
  // background-image: url('../../../assets/nImages/black_arrow-down.svg');
}

.dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.dropdownList {
  font-size: 1.125rem;
  cursor: default;
  max-height: 300px;
  overflow-y: auto;
  padding: 1rem;
}

.dropSelectAll {
  border-bottom: 1px solid var(--color-gray2);
}

.dropdownItem {
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > div:first-child {
    flex-grow: 1;
  }

  & > div:last-child {
    margin: 0 1.5rem;
  }
}

.imageView {
  cursor: pointer;
}


.paragraph{
  padding: 0.25rem 0;
  margin: 0;
}