.customerSatisfaction {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.satisfactionBoard {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 1.94rem;
  margin-bottom: 1rem;
  justify-content: center;
}

.satisfactionItem {
  width: 45%;
}

.satisfactionItem:not(:first-child) {
  margin-left: 6.18rem;
}

.noEvent {
  margin-top: 2rem;
  width: 100%;
}

.commentsBoard{
  width: 100%;
  justify-content: center;
  background-color: #d9d9d9;
  padding: 1.5rem;
  border-radius: 10px;
  margin-top: 1.5rem;

  .comment{
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.satisfactionContainer{
  display: flex;
}

.patientVisitDetails{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
  .patientVisitBtn {
    margin-left: 1rem;
    width: auto;
    min-width: 7.5rem;
    padding: 5px;
    height: 3rem;
    border: 1px solid var(--color-menuGreen);
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: transparent;
    color: var(--color-menuGreen);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.pvActiveVisit {
      background-color: var(--color-menuGreen);
      color: white;
      cursor: default;
    }
  }
}